import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"no-gutters":"","justify":"space-between"}},[(!_vm.is_editing && !_vm.create_new && _vm.chip_color)?_c(VCol,{attrs:{"cols":_vm.removable && _vm.is_editing ? '11': '12'}},[_c(VCardText,{staticClass:"pa-0"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.translate(_vm.label))+" ")])]),_c('AppChip',{attrs:{"label":_vm.label,"value":_vm.locale_key(_vm.value, _vm.enum_locations, true) || '-',"property":_vm.property,"resource":_vm.resource,"color":_vm.chip_color}})],1):(!_vm.is_readonly)?_c(VCol,{attrs:{"cols":_vm.removable && _vm.is_editing ? '11': '12'}},[_c(VSelect,{attrs:{"value":_vm.value,"items":_vm.key_locale_array(_vm.property.enum, _vm.enum_locations),"disabled":_vm.is_readonly,"placeholder":_vm.text_field_hint(),"label":_vm.label,"readonly":_vm.is_readonly,"filled":_vm.is_readonly,"clearable":(_vm.is_editing || _vm.create_new)  && !_vm.removable,"rules":(_vm.is_editing || _vm.create_new) ? [_vm.validate(_vm.value, _vm.property, _vm.original_value)] : [],"error-messages":_vm.translate(_vm.error_message),"item-text":"locale","item-value":"key","outlined":"","dense":""},on:{"input":_vm.handle_input}})],1):_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"value":_vm.locale_key(_vm.value, _vm.enum_locations, true),"type":_vm.property.type === 'integer' || _vm.property.type === 'number' ? 'number' : _vm.property.type,"label":_vm.label,"placeholder":_vm.field_placeholder(),"hint":_vm.field_hint(),"readonly":"","filled":"","outlined":"","dense":""}})],1),(_vm.removable && _vm.is_editing)?_c(VCol,{attrs:{"cols":"1"}},[_c(VRow,{attrs:{"no-gutters":"","justify":"end"}},[_c(VBtn,{attrs:{"color":"error","icon":"","large":""},on:{"click":function($event){return _vm.$emit('remove_value_from_array')}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }