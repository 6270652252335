import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c(VCol,{staticClass:"pa-2",attrs:{"cols":"12"}},[_c(VCard,{staticClass:"mx-auto",attrs:{"min-height":"325px","elevation":"12"}},[_c(VCardTitle,{staticClass:"primary"},[_c(VSpacer),_c('span',{staticStyle:{"color":"whitesmoke"}},[_vm._v("Select System")]),_c(VSpacer)],1),_c(VRow,{staticClass:"ma-4",attrs:{"no-gutters":"","align":"stretch","justify":"center"}},_vm._l((_vm.existing_system_details),function(item){return _c(VCol,{key:item.system,staticClass:"d-flex flex-column",attrs:{"cols":"12","xs":"12","sm":"12","md":"6","lg":"4","xl":"3"}},[_c(VCard,{staticClass:"ma-4 flex d-flex flex-column",attrs:{"hover":"","raised":"","rounded":"xl"},on:{"click":function($event){return _vm.select_system(item)}}},[_c(VCardTitle,{staticClass:"grey lighten-3"},[_c(VIcon,{staticClass:"mr-2",attrs:{"color":"primary","large":""}},[_vm._v(" "+_vm._s(item.system_icon)+" ")]),_vm._v(" "+_vm._s(item.system_name)+" ")],1),_c(VDivider),_c(VCardText,[_vm._v(_vm._s(item.description))])],1)],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }