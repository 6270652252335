import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dependency_links_list.length)?_c(VMenu,{staticClass:"app-layout--level-502",attrs:{"offset-x":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-link-variant ")])],1)]}}],null,false,2165717062)},[_c(VCard,{staticStyle:{"overflow-y":"auto"},attrs:{"height":"450px","min-width":"250px"}},[_c(VList,_vm._l((_vm.dependency_links_list),function(dependency_resource,key){return _c(VListItem,{key:key},[_c('Link',{attrs:{"value":dependency_resource,"resource":_vm.resource,"link_results":_vm.items_per_dependency[dependency_resource],"icon":_vm.get_resource_icon(dependency_resource),"multiple_dependencies":_vm.get_dependency(dependency_resource).length > 1,"single_dependency":_vm.get_dependency(dependency_resource).length === 1,"display_create_new":_vm.has_post_access(dependency_resource),"show-label":"","full-width":""},on:{"click":function($event){return _vm.go_to_dependency(dependency_resource, $event)},"create_new":function($event){return _vm.create_new_dependency_document(dependency_resource, $event)}}})],1)}),1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }