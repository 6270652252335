import { render, staticRenderFns } from "./RegisterSystem.vue?vue&type=template&id=1852f474&scoped=true&"
import script from "./RegisterSystem.vue?vue&type=script&lang=js&"
export * from "./RegisterSystem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1852f474",
  null
  
)

export default component.exports