import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAutocomplete,{attrs:{"items":_vm.icons,"label":_vm.label,"readonly":_vm.readonly,"filled":_vm.readonly,"clearable":_vm.clearable,"outlined":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"mr-4"},[_vm._v(" "+_vm._s(item)+" ")]),_c('span',[_vm._v(" "+_vm._s(item)+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"mr-4"},[_vm._v(" "+_vm._s(item)+" ")]),_vm._v(" "+_vm._s(item)+" ")]}}]),model:{value:(_vm.internal_value),callback:function ($$v) {_vm.internal_value=$$v},expression:"internal_value"}})}
var staticRenderFns = []

export { render, staticRenderFns }