import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"width":"500"},model:{value:(_vm.internal_value),callback:function ($$v) {_vm.internal_value=$$v},expression:"internal_value"}},[_c(VToolbar,{attrs:{"color":"primary","height":"48px","flat":"","dark":""}},[_vm._v(" "+_vm._s(String.format(_vm.$t('actions.may_not_be_performed'), _vm.title))+" ")]),_c(VCard,{attrs:{"tile":""}},[_c(VRow,{staticClass:"pa-4",attrs:{"no-gutters":""}},[_c('ul',_vm._l((_vm.issues),function(issue,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(_vm.translate(issue))+" ")])}),0)]),_c(VCardActions,{staticClass:"pt-8"},[_c(VSpacer),_c(VBtn,{attrs:{"small":_vm.$vuetify.breakpoint.xsOnly,"color":"primary"},on:{"click":function($event){_vm.internal_value = false}}},[_vm._v(" "+_vm._s(_vm.$t('common.ok'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }