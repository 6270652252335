import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VSnackbar,{staticStyle:{"z-index":"99"},attrs:{"color":_vm.color,"timeout":_vm.timeout,"absolute":"","bottom":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({staticStyle:{"margin-right":"8px !important"},attrs:{"icon":"","small":""},on:{"click":function($event){_vm.internal_value = false}}},'v-btn',attrs,false),[_c(VIcon,[_vm._v(" mdi-close ")])],1)]}}]),model:{value:(_vm.internal_value),callback:function ($$v) {_vm.internal_value=$$v},expression:"internal_value"}},[_vm._v(" "+_vm._s(_vm.translate(_vm.text))+" "),(_vm.json)?_c(VBtn,{staticClass:"mx-2",attrs:{"outlined":"","small":""},on:{"click":function($event){_vm.display_json_dialog = true}}},[_vm._v(" "+_vm._s(_vm.$t('common.details'))+" ")]):_vm._e()],1),_c('AppDialog',{attrs:{"title":"common.details","height":"64vh","remove_border_top_radius":true,"remove_border_bottom_radius":true,"medium":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('json-viewer',{attrs:{"value":_vm.computed_json,"preview-mode":""}})]},proxy:true},{key:"footer",fn:function(){return [_c(VToolbar,{attrs:{"dense":""}},[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){_vm.display_json_dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('common.ok'))+" ")])],1)]},proxy:true}]),model:{value:(_vm.display_json_dialog),callback:function ($$v) {_vm.display_json_dialog=$$v},expression:"display_json_dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }