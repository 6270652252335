import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VHover,{attrs:{"close-delay":"500"},model:{value:(_vm.menu_button_hover),callback:function ($$v) {_vm.menu_button_hover=$$v},expression:"menu_button_hover"}},[_c(VListItem,_vm._g(_vm._b({staticClass:"font-weight-light app-object--rounded text-capitalize v-btn",class:_vm.menu_selected(_vm.item, _vm.$route) ? 'v-list-item--active primary--text' :
                                                            {'v-btn--active': _vm.menu_open_computed},attrs:{"dense":"","height":"100%"}},'v-list-item',attrs,false),on),[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.translate(_vm.item['locale_name']))+" ")]),_c(VListItemIcon,[_c(VIcon,[_vm._v(" mdi-chevron-right ")])],1)],1)],1)]}}]),model:{value:(_vm.menu_open_computed),callback:function ($$v) {_vm.menu_open_computed=$$v},expression:"menu_open_computed"}},[_c(VHover,{attrs:{"close-delay":"500"},model:{value:(_vm.menu_content_hover),callback:function ($$v) {_vm.menu_content_hover=$$v},expression:"menu_content_hover"}},[_c(VList,{staticStyle:{"padding":"0"},attrs:{"dense":""}},_vm._l((_vm.item['list_menu']),function(list_menu_item,list_menu_index){return _c(VListItem,{key:list_menu_index,class:_vm.menu_selected(list_menu_item, _vm.$route)
                                        ? 'v-btn--active primary--text font-weight-regular text-capitalize'
                                        : 'font-weight-regular text-capitalize',attrs:{"to":list_menu_item}},[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.translate(list_menu_item['locale_name']))+" ")])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }