import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticClass:"user-authentication__bg"}),_c(VAlert,{attrs:{"prominent":"","type":"error"}},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{staticClass:"text-center grow"},[_vm._v(" "+_vm._s(_vm.too_many_requests_message)+" ")])],1)],1),_c(VRow,{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c(VCol,{staticClass:"pa-2",attrs:{"cols":"12","xs":"12","sm":"6","md":"6","lg":"4","xl":"4"}},[_c(VCard,{staticClass:"mx-auto",style:('margin-top: ' + (_vm.$vuetify.breakpoint.xsOnly ? '10px' : '80px') + ';margin-bottom: 150px'),attrs:{"min-height":"325px","elevation":"12"}},[_c(VCardTitle,{staticClass:"secondary"},[_c(VSpacer),_c('span',{staticStyle:{"color":"whitesmoke"}},[_vm._v(_vm._s(_vm.system_name))]),_c(VSpacer)],1),_c(VRow,{staticClass:"mt-12",attrs:{"justify":"center","no-gutters":""}},[_c(VBtn,{attrs:{"small":_vm.small_screen,"color":"primary"},on:{"click":function($event){return _vm.sign_out('manual_sign_out')}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-logout")]),_vm._v(" "+_vm._s(_vm.$t('common.logout'))+" ")],1)],1),_c(VRow,{staticClass:"mt-12",attrs:{"justify":"center","no-gutters":""}},[_c(VBtn,{attrs:{"small":_vm.small_screen,"color":"secondary"},on:{"click":function($event){return _vm.refresh()}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-refresh-circle")]),_vm._v(" "+_vm._s(_vm.$t('common.try_again'))+" ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }