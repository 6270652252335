import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VHover,{attrs:{"close-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{class:_vm.$vuetify.theme.dark ? (_vm.card_class + " darken-3") : (_vm.card_class + " lighten-2"),staticStyle:{"user-select":"none","margin-right":"0.1rem !important"},attrs:{"elevation":_vm.tile ? '0' : '4',"color":!hover && !_vm.active ?  _vm.inactive_color: _vm.active_color,"tile":_vm.tile,"width":_vm.width ? _vm.width : ''},on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.label)?_c(VCardSubtitle,{staticClass:"pa-0 v-label",attrs:{"id":"label"}},[_vm._v(" "+_vm._s(_vm.locale_key(_vm.label, null, true))+" ")]):_vm._e(),_c(VRow,{attrs:{"no-gutters":"","justify":"space-between","align":"center"}},[_c(VCol,{attrs:{"cols":"10"}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCardText,{class:_vm.label ? (_vm.text_class + " pt-1") : _vm.text_class},[_vm._v(" "+_vm._s(_vm.translate(_vm.text) || '-')+" ")])],1)],1),_c(VBtn,{staticClass:"app-object--absolute-top-right app-object--absolute-center-y",attrs:{"icon":"","small":""},on:{"click":[function($event){$event.stopPropagation();},function($event){return _vm.$emit('close')}]}},[_c(VIcon,{attrs:{"small":""}},[_vm._v(" mdi-close-box ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }