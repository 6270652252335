import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.internal_value)?_c(VCard,{attrs:{"color":"transparent","flat":"","tile":""}},[(_vm.title)?_c(VCardTitle,{class:_vm.highlight_title ? 'app-text--highlighted pb-2 px-0' : 'pb-2 px-0',domProps:{"textContent":_vm._s(_vm.translate(_vm.title))}}):_vm._e(),_c(VCard,[_c(VCardActions,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VBtn,{staticClass:"ma-2",attrs:{"icon":""},on:{"click":function($event){_vm.expanded = !_vm.expanded}}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.expanded ? 'mdi-chevron-down' : 'mdi-chevron-right')+" ")])],1),_c('JsonViewer',{key:_vm.expanded,staticClass:"app-object--click-disabled my-4",attrs:{"expand-depth":0,"preview-mode":_vm.expanded},model:{value:(_vm.internal_value),callback:function ($$v) {_vm.internal_value=$$v},expression:"internal_value"}})],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }