import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{staticClass:"app-app-header",attrs:{"color":_vm.bar_color,"absolute":""}},[_c(VToolbarTitle,{staticClass:"pr-3"},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"hidden-md-and-up"},[_c(VBtn,{attrs:{"id":"#bm_icon","color":_vm.environment_color,"icon":""},on:{"click":function($event){return _vm.$emit('open_left_navigation_drawer')}}},[_c(VIcon,[_vm._v("mdi-menu")])],1)],1),_c(VCol,{staticClass:"hidden-sm-and-down ma-auto"},[_c(VBtn,{staticClass:"title font-weight-light app-object--rounded",attrs:{"id":"help_home","color":_vm.environment_color,"x-large":"","text":"","tile":""},on:{"click":function($event){return _vm.go_to('Home')}}},[_c(VIcon,[_vm._v("mdi-home")])],1)],1)],1)],1),_c(VDivider,{attrs:{"vertical":""}}),_c('TheHeaderMenu',{staticClass:"hidden-sm-and-down pl-3",staticStyle:{"height":"100%"}}),_c(VSpacer),_c('TheHeaderIconBar',{on:{"open_right_navigation_drawer":function($event){return _vm.$emit('open_right_navigation_drawer')},"right_navigation_component":function($event){return _vm.$emit('right_navigation_component', $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }