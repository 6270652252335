import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"open-on-hover":_vm.menu_open,"left":_vm.left,"offset-x":"","tile":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.item.children)?_c(VHover,{attrs:{"close-delay":"20"},model:{value:(_vm.menu_open),callback:function ($$v) {_vm.menu_open=$$v},expression:"menu_open"}},[_c(VListItem,_vm._g({style:(_vm.css_props),on:{"click":function($event){return _vm.handle_click(_vm.item)}}},on),[(_vm.item.icon)?_c(VIcon,{staticClass:"pr-2",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.item.icon)+" ")]):_vm._e(),_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.item.text)+" ")]),(_vm.item.children)?_c(VIcon,{staticStyle:{"margin-left":"auto"},attrs:{"small":""}},[_vm._v(" mdi-chevron-right ")]):_vm._e()],1)],1):_c(VListItem,{style:(_vm.css_props),on:{"click":function($event){return _vm.handle_click(_vm.item)}},model:{value:(_vm.menu_open),callback:function ($$v) {_vm.menu_open=$$v},expression:"menu_open"}},[(_vm.item.icon)?_c(VIcon,{staticClass:"pr-2",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.item.icon)+" ")]):_vm._e(),_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.item.text)+" ")])],1)]}}]),model:{value:(_vm.internal_menu_open),callback:function ($$v) {_vm.internal_menu_open=$$v},expression:"internal_menu_open"}},[_c(VHover,{attrs:{"close-delay":"20"},model:{value:(_vm.sub_menu_open),callback:function ($$v) {_vm.sub_menu_open=$$v},expression:"sub_menu_open"}},[_c(VList,{staticClass:"pa-0",attrs:{"dense":""}},_vm._l((_vm.item.children),function(item,index){return _c('ItemActionMenuMenuSubItem',{key:index,attrs:{"item":item,"index":index,"position":_vm.position,"css_props":_vm.css_props},on:{"index":function($event){_vm.sub_sub_menu_open = index},"index_list":function($event){return _vm.set_sub_sub_menu(index, $event)},"action":_vm.handle_click,"issues":function($event){return _vm.$emit('issues', $event)}},model:{value:(_vm.sub_sub_menu_open),callback:function ($$v) {_vm.sub_sub_menu_open=$$v},expression:"sub_sub_menu_open"}})}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }