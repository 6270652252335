import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dialogs && _vm.dialogs.length && _vm.swagger_loaded)?_c(VFooter,{staticClass:"pa-0 app-layout--level-500 app-object--click-disabled",attrs:{"color":"transparent","fixed":""}},[((_vm.$vuetify.breakpoint.mdOnly && _vm.dialogs.length < 6) || (_vm.$vuetify.breakpoint.lgOnly && _vm.dialogs.length < 7) || (_vm.$vuetify.breakpoint.xlOnly && _vm.dialogs.length < 11))?_c(VRow,{attrs:{"no-gutters":"","justify":"center"}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,{attrs:{"no-gutters":""}},_vm._l((_vm.dialogs),function(dialog,index){return _c('ItemDialogManagerMinimizedCard',{key:("minimized_" + index),staticClass:"app-layout--level-500 app-object--click-enabled",attrs:{"value":dialog,"maximized":_vm.current_dialog && dialog.item['_id'] === _vm.current_dialog.item['_id'],"width":"175px"},on:{"maximize":function($event){return _vm.maximize(dialog)},"minimize":function($event){return _vm.minimize(dialog)},"close":function($event){return _vm.close(dialog)}}})}),1)],1)],1):_c(VMenu,{attrs:{"top":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBadge,{attrs:{"value":_vm.dialogs.length > 0,"content":_vm.dialogs.length,"offset-x":22,"offset-y":20,"overlap":"","color":"accent"}},[_c(VBtn,_vm._g(_vm._b({staticClass:"app-object--click-enabled",attrs:{"text":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-table ")])],1)],1)]}}],null,false,1632491363)},[_c(VCard,{attrs:{"width":"225px"}},[_c(VRow,{attrs:{"no-gutters":"","justify":"space-between","align":"center"}},[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t('common.dialogs'))+" ")]),_c(VBtn,{staticClass:"mr-1",attrs:{"icon":""},on:{"click":_vm.clear_all}},[_c(VIcon,[_vm._v(" mdi-delete-outline ")])],1)],1),_c(VDivider),_c(VCard,{staticStyle:{"overflow-y":"auto"},attrs:{"height":"325px"}},[_c(VRow,{attrs:{"no-gutters":""}},_vm._l((_vm.dialogs),function(dialog,index){return _c(VCol,{key:("minimized_" + index),staticClass:"mt-1",attrs:{"cols":"12"}},[_c('MinimizedCard',{staticClass:"app-object--click-enabled",attrs:{"value":dialog,"maximized":_vm.current_dialog && dialog.item['_id'] === _vm.current_dialog.item['_id'],"tile":""},on:{"maximize":function($event){return _vm.maximize(dialog)},"close":function($event){return _vm.close(dialog)}}})],1)}),1)],1)],1)],1)],1):_vm._e(),(_vm.current_dialog)?_c('ItemDialog',{key:((_vm.current_dialog.item._id) + "_" + (_vm.current_dialog.create_new)),attrs:{"resource":_vm.current_dialog.resource,"create_new":_vm.current_dialog.create_new,"init_editing":_vm.current_dialog.init_editing,"display":""},on:{"close":function($event){return _vm.close(_vm.current_dialog)},"minimize":function($event){return _vm.minimize(_vm.current_dialog)},"reset_init_editing":function($event){return _vm.reset_init_editing(_vm.current_dialog)}},model:{value:(_vm.current_dialog.item),callback:function ($$v) {_vm.$set(_vm.current_dialog, "item", $$v)},expression:"current_dialog.item"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }